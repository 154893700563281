"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MinutesUltrStatic = exports.TimeSlots = exports.TransportMode = exports.TAT = exports.AM = exports.Minutes = exports.Hours = exports.auditeeTypeList = exports.maxDropdownOptions = exports.defaultPageLimit = exports.paginationDropdown = exports.reportDownload = exports.ReloadListContext = exports.dummyDropdownList = exports.fixedDateTimeFormatt = exports.UpdatedDateFormat = exports.fixedDateFormatt = exports.X_AUTH_TOKEN_REQUIRED = exports.LOGINAPIFAIL = exports.APPLICAION_CURRENT_VERSION = exports.CONFIGAPIFAIL = exports.ERRORMESSAGE = exports.maxDropdownList = exports.minDropdownList = exports.questionTypeDropdown = exports.totalQuestionsDropdown = exports.targetAuditorsDropdownList = exports.targetAuditeeeDropdownList = exports.targetAudienceDropdownList = exports.countryDropdownList = exports.RejectOndemand = exports.CreateOnDemandRequest = exports.AddVisit = exports.bookMark = exports.VENDOR_STATUS = exports.FE_USER_TYPES = exports.Paths = exports.days = exports.Days = exports.Months = exports.AppConstants = void 0;
var React = require("react");
exports.AppConstants = {
    ComponentsType: {
        Dashboard: 'dashboard',
        Settings: 'settings',
    },
    RoleDetails: [
        { id: 1, name: 'LG Super Admin' },
        { id: 2, name: 'Vendor Admin' },
        { id: 3, name: 'CC Admin' },
    ],
    languages: ['English', 'Spanish'],
};
exports.Months = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
];
exports.Days = [
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
    'Sunday',
];
exports.days = {
    Monday: '',
    Tuesday: '',
    Wednesday: '',
    Thursday: '',
    Friday: '',
    Saturday: '',
    Sunday: '',
};
exports.Paths = {
    default: '/',
    login: '/login',
    profile: '/profile',
    dashboard: '/dashboard',
};
exports.FE_USER_TYPES = {
    active: 'Active',
    drafted: 'Draft',
    exited: 'Exited',
    onLeaveToday: 'APPROVED',
    rejected: 'Rejected',
    inactive: 'In-Active',
    inventoryPending: 'Approved',
    inActive: 'In-Active',
    levelOne: 'Pending for approval',
    levelTwo: 'Pending for approval',
    ultraStatic: 'Ultra Static'
};
exports.VENDOR_STATUS = {
    active: 1,
    drafted: 2,
    exited: 3,
    levelOne: 5,
    levelTwo: 6,
    rejected: 8,
};
exports.bookMark = {
    Add: 'Added to bookmarks list',
    remove: 'Removed from bookmarks list',
};
exports.AddVisit = {
    Add: 'On Demand Request Assigned Successfully',
    remove: 'On Demand Request is not Assigned',
};
exports.CreateOnDemandRequest = {
    Add: 'On Demand Request Created Successfully',
    remove: 'Same slot is existed for the user',
};
exports.RejectOndemand = {
    Add: 'Rejected OnDemand Request Successfully',
    remove: '',
};
exports.countryDropdownList = [
    {
        id: 1,
        label: 'India',
        value: 1,
    },
];
exports.targetAudienceDropdownList = [
    {
        id: 1,
        label: 'FE',
        value: 1,
        disabled: true,
    },
    {
        id: 2,
        label: 'CC',
        value: 2,
        disabled: true,
    },
    {
        id: 3,
        label: 'Lab',
        value: 3,
        disabled: true,
    },
    {
        id: 4,
        label: 'Vendor',
        value: 4,
        disabled: true,
    },
];
exports.targetAuditeeeDropdownList = [
    {
        id: 1,
        label: 'ALL',
        value: 1,
        disabled: false,
    },
    {
        id: 2,
        label: 'CC',
        value: 2,
        disabled: false,
    },
    {
        id: 3,
        label: 'PUP',
        value: 3,
        disabled: false,
    },
    {
        id: 4,
        label: 'PSC',
        value: 4,
        disabled: false,
    },
];
exports.targetAuditorsDropdownList = [
    {
        id: 1,
        label: 'FE',
        value: 1,
        disabled: false,
    },
    {
        id: 2,
        label: 'CC',
        value: 2,
        disabled: true,
    },
    {
        id: 3,
        label: 'Lab',
        value: 3,
        disabled: true,
    },
    {
        id: 4,
        label: 'Vendor',
        value: 4,
        disabled: true,
    },
];
exports.totalQuestionsDropdown = [
    {
        id: 1,
        label: '1',
        value: 1,
    },
    {
        id: 2,
        label: '2',
        value: 2,
    },
    {
        id: 3,
        label: '3',
        value: 3,
    },
    {
        id: 4,
        label: '4',
        value: 4,
    },
    {
        id: 5,
        label: '5',
        value: 5,
    },
    {
        id: 6,
        label: '6',
        value: 6,
    },
];
exports.questionTypeDropdown = [
    {
        id: 1,
        label: 'Single Choice',
        value: 1,
    },
    {
        id: 2,
        label: 'Multiple Choice',
        value: 2,
    },
    {
        id: 3,
        label: 'True/False',
        value: 3,
    },
    {
        id: 4,
        label: 'Linear Scale',
        value: 4,
    },
    {
        id: 5,
        label: 'Short Answer',
        value: 5,
    },
    {
        id: 6,
        label: 'Mobile Number',
        value: 6,
    },
];
exports.minDropdownList = [
    {
        id: 1,
        label: '1',
        value: 1,
    },
    {
        id: 2,
        label: '2',
        value: 2,
    },
    {
        id: 3,
        label: '3',
        value: 3,
    },
    {
        id: 4,
        label: '4',
        value: 4,
    },
    {
        id: 5,
        label: '5',
        value: 5,
    },
];
exports.maxDropdownList = [
    {
        id: 1,
        label: '1',
        value: 1,
    },
    {
        id: 2,
        label: '2',
        value: 2,
    },
    {
        id: 3,
        label: '3',
        value: 3,
    },
    {
        id: 4,
        label: '4',
        value: 4,
    },
    {
        id: 5,
        label: '5',
        value: 5,
    },
];
exports.ERRORMESSAGE = 'Unable to process your request';
exports.CONFIGAPIFAIL = 'unable to fetch application config data,please contact your admin';
exports.APPLICAION_CURRENT_VERSION = '0.0.144';
exports.LOGINAPIFAIL = 'Invalid Credentails';
exports.X_AUTH_TOKEN_REQUIRED = '"x-auth-token" is required';
exports.fixedDateFormatt = 'DD-MM-YYYY';
exports.UpdatedDateFormat = 'DD/MM/YYYY';
exports.fixedDateTimeFormatt = 'DD-MM-YYYY HH:MM:SS';
exports.dummyDropdownList = [
    {
        id: 1,
        value: 'dummy',
        label: 'dummy',
    },
    {
        id: 2,
        value: 'dummy',
        label: 'dummy',
    },
    {
        id: 3,
        value: 'dummy',
        label: 'dummy',
    },
    {
        id: 4,
        value: 'dummy',
        label: 'dummy',
    },
];
exports.ReloadListContext = React.createContext(null);
exports.reportDownload = 'Download';
exports.paginationDropdown = [
    {
        id: 1,
        label: '50',
        value: 50,
    },
    {
        id: 2,
        label: '100',
        value: 100,
    },
    {
        id: 3,
        label: '250',
        value: 250,
    },
    {
        id: 4,
        label: '500',
        value: 500,
    },
];
exports.defaultPageLimit = {
    id: 5,
    label: '50',
    value: 50,
};
exports.maxDropdownOptions = 2000;
exports.auditeeTypeList = [
    {
        id: 2,
        label: 'CC',
        value: 'CC',
        disabled: false,
    },
    {
        id: 1,
        label: 'FE',
        value: 'FE',
        disabled: true,
    },
    {
        id: 3,
        label: 'Lab',
        value: 'Lab',
        disabled: true,
    },
    {
        id: 4,
        label: 'Vendor',
        value: 'Vendor',
        disabled: true,
    },
];
exports.Hours = [
    '00',
    '01',
    '02 ',
    '03',
    '04',
    '05',
    '06',
    '07',
    '08',
    '09',
    '10',
    '11',
    '12',
    '13',
    '14',
    '15',
    '16',
    '17',
    '18',
    '19',
    '20',
    '21',
    '22 ',
    '23',
];
exports.Minutes = [
    '00',
    '01',
    '02 ',
    '03',
    '04',
    '05',
    '06',
    '07',
    '08',
    '09',
    '10',
    '11',
    '12',
    '13',
    '14',
    '15',
    '16',
    '17',
    '18',
    '19',
    '20',
    '21',
    '22 ',
    '23',
    '24',
    '25',
    '26',
    '27',
    '28',
    '29',
    '30',
    '31',
    '32 ',
    '33',
    '34',
    '35',
    '36',
    '37',
    '38',
    '39',
    '40',
    '41',
    '42 ',
    '43',
    '44',
    '45',
    '46',
    '47',
    '48',
    '49',
    '50',
    '51',
    '52 ',
    '53',
    '54',
    '55',
    '56',
    '57',
    '58',
    '59',
];
exports.AM = ['AM', 'PM'];
exports.TAT = [
    {
        id: 0,
        label: 'Same Day',
        value: 0,
        hours: 0,
    },
    {
        id: 1,
        label: 'Next Day',
        value: 1,
        hours: 24,
    },
    {
        id: 2,
        label: 'Day plus One',
        value: 2,
        hours: 48,
    },
    {
        id: 3,
        label: 'Day plus Two',
        value: 3,
        hours: 72,
    },
];
exports.TransportMode = [
    {
        id: 1,
        label: 'MULTIMODAL',
        value: 'MULTIMODAL',
    },
    {
        id: 2,
        label: 'OBC/OTC',
        value: 'OBC/OTC',
    },
    {
        id: 3,
        label: 'HAND DELIVERY',
        value: 'HAND DELIVERY',
    },
    {
        id: 4,
        label: 'AIRLINES VENDOR',
        value: 'AIRLINES VENDOR',
    },
    {
        id: 5,
        label: 'COURIER VENDOR',
        value: 'COURIER VENDOR',
    },
    {
        id: 6,
        label: 'OTHER',
        value: 'OTHER',
    },
    {
        id: 7,
        label: 'VAN',
        value: 'VAN',
    },
];
exports.TimeSlots = [
    {
        id: 1,
        label: '00:00 - 01:00',
        value: '00:00 - 01:00',
    },
    {
        id: 2,
        label: '01:00 - 02:00',
        value: '01:00 - 02:00',
    },
    {
        id: 3,
        label: '02:00 - 03:00',
        value: '02:00 - 03:00',
    },
    {
        id: 4,
        label: '03:00 - 04:00',
        value: '03:00 - 04:00',
    },
    {
        id: 5,
        label: '04:00 - 05:00',
        value: '04:00 - 05:00',
    },
    {
        id: 6,
        label: '05:00 - 06:00',
        value: '05:00 - 06:00',
    },
    {
        id: 7,
        label: '06:00 - 07:00',
        value: '06:00 - 07:00',
    },
    {
        id: 8,
        label: '07:00 - 08:00',
        value: '07:00 - 08:00',
    },
    {
        id: 9,
        label: '08:00 - 09:00',
        value: '08:00 - 09:00',
    },
    {
        id: 10,
        label: '09:00 - 10:00',
        value: '09:00 - 10:00',
    },
    {
        id: 11,
        label: '10:00 - 11:00',
        value: '10:00 - 11:00',
    },
    {
        id: 12,
        label: '11:00 - 12:00',
        value: '11:00 - 12:00',
    },
    {
        id: 13,
        label: '12:00 - 13:00',
        value: '12:00 - 13:00',
    },
    {
        id: 14,
        label: '13:00 - 14:00',
        value: '13:00 - 14:00',
    },
    {
        id: 15,
        label: '14:00 - 15:00',
        value: '14:00 - 15:00',
    },
    {
        id: 16,
        label: '15:00 - 16:00',
        value: '15:00 - 16:00',
    },
    {
        id: 17,
        label: '16:00 - 17:00',
        value: '16:00 - 17:00',
    },
    {
        id: 18,
        label: '17:00 - 18:00',
        value: '17:00 - 18:00',
    },
    {
        id: 19,
        label: '18:00 - 19:00',
        value: '18:00 - 19:00',
    },
    {
        id: 20,
        label: '19:00 - 20:00',
        value: '19:00 - 20:00',
    },
    {
        id: 21,
        label: '20:00 - 21:00',
        value: '20:00 - 21:00',
    },
    {
        id: 22,
        label: '21:00 - 22:00',
        value: '21:00 - 22:00',
    },
    {
        id: 23,
        label: '22:00 - 23:00',
        value: '22:00 - 23:00',
    },
    {
        id: 24,
        label: '23:00 - 00:00',
        value: '23:00 - 00:00',
    },
];
exports.MinutesUltrStatic = [
    { id: 0, label: '00', value: '00' },
    { id: 1, label: '01', value: '01' },
    { id: 2, label: '02', value: '02' },
    { id: 3, label: '03', value: '03' },
    { id: 4, label: '04', value: '04' },
    { id: 5, label: '05', value: '05' },
    { id: 6, label: '06', value: '06' },
    { id: 7, label: '07', value: '07' },
    { id: 8, label: '08', value: '08' },
    { id: 9, label: '09', value: '09' },
    { id: 10, label: '10', value: '10' },
    { id: 11, label: '11', value: '11' },
    { id: 12, label: '12', value: '12' },
    { id: 13, label: '13', value: '13' },
    { id: 14, label: '14', value: '14' },
    { id: 15, label: '15', value: '15' },
    { id: 16, label: '16', value: '16' },
    { id: 17, label: '17', value: '17' },
    { id: 18, label: '18', value: '18' },
    { id: 19, label: '19', value: '19' },
    { id: 20, label: '20', value: '20' },
    { id: 21, label: '21', value: '21' },
    { id: 22, label: '22', value: '22' },
    { id: 23, label: '23', value: '23' },
    { id: 24, label: '24', value: '24' },
    { id: 25, label: '25', value: '25' },
    { id: 26, label: '26', value: '26' },
    { id: 27, label: '27', value: '27' },
    { id: 28, label: '28', value: '28' },
    { id: 29, label: '29', value: '29' },
    { id: 30, label: '30', value: '30' },
    { id: 31, label: '31', value: '31' },
    { id: 32, label: '32', value: '32' },
    { id: 33, label: '33', value: '33' },
    { id: 34, label: '34', value: '34' },
    { id: 35, label: '35', value: '35' },
    { id: 36, label: '36', value: '36' },
    { id: 37, label: '37', value: '37' },
    { id: 38, label: '38', value: '38' },
    { id: 39, label: '39', value: '39' },
    { id: 40, label: '40', value: '40' },
    { id: 41, label: '41', value: '41' },
    { id: 42, label: '42', value: '42' },
    { id: 43, label: '43', value: '43' },
    { id: 44, label: '44', value: '44' },
    { id: 45, label: '45', value: '45' },
    { id: 46, label: '46', value: '46' },
    { id: 47, label: '47', value: '47' },
    { id: 48, label: '48', value: '48' },
    { id: 49, label: '49', value: '49' },
    { id: 50, label: '50', value: '50' },
    { id: 51, label: '51', value: '51' },
    { id: 52, label: '52', value: '52' },
    { id: 53, label: '53', value: '53' },
    { id: 54, label: '54', value: '54' },
    { id: 55, label: '55', value: '55' },
    { id: 56, label: '56', value: '56' },
    { id: 57, label: '57', value: '57' },
    { id: 58, label: '58', value: '58' },
    { id: 59, label: '59', value: '59' },
];
