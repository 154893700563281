"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var react_router_dom_1 = require("react-router-dom");
var button_component_1 = require("../../../common/buttons/button_component");
var route_path_1 = require("../../../constants/route_path");
var array_helpers_1 = require("../../../utils/array_helpers");
var menu_constants_1 = require("../../../menuList/menu_constants");
var ManageAccessButton = function (_a) {
    var data = _a.data;
    var nav = (0, react_router_dom_1.useNavigate)();
    var features = (0, array_helpers_1.getAccessibleFeatures)(menu_constants_1.FEATURE_CONSTANTS.Admin_User_Management);
    var EditUserHandler = function () {
        nav("/".concat(route_path_1.default.PATHS.User_Logistics, "/").concat(route_path_1.default.PATHS.Edit_Region, "/").concat(data.id));
    };
    return (React.createElement("div", { className: "regionicons" },
        React.createElement("div", null,
            React.createElement(button_component_1.default, { buttonName: 'Manage Access', color: "yellow-outline", onClickHandler: EditUserHandler, isError: !features[menu_constants_1.FEATURE_CONSTANTS.adminUserCreate] }))));
};
exports.default = ManageAccessButton;
