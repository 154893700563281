"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
var Grid_1 = require("@mui/material/Grid");
var formik_1 = require("formik");
var React = require("react");
var react_router_1 = require("react-router");
var button_component_1 = require("../../../common/buttons/button_component");
var iconButton_component_1 = require("../../../common/buttons/iconButton_component");
var route_path_1 = require("../../../constants/route_path");
var drop_down_component_1 = require("../../../common/drop_down/drop_down_component");
var Region_Selector_1 = require("./Region_Selector");
var react_hot_toast_1 = require("react-hot-toast");
var user_logistics_api_1 = require("./user_logistics_api");
var multi_selector_1 = require("../../../common/checkbox/multi_selector");
var pop_up_1 = require("../../../common/modal/pop-up");
var react_redux_1 = require("react-redux");
var AssignRegion = function () {
    var state = (0, react_router_1.useLocation)().state;
    var nav = (0, react_router_1.useNavigate)();
    var id = (0, react_router_1.useParams)().id;
    var _a = React.useState({}), selectedStates = _a[0], setSelectedStates = _a[1];
    var _b = React.useState({}), selectedCities = _b[0], setSelectedCities = _b[1];
    var _c = React.useState({}), selectedLabs = _c[0], setSelectedLabs = _c[1];
    var _d = React.useState(), allCities = _d[0], setAllCities = _d[1];
    var _e = React.useState(), allLabs = _e[0], setAllLabs = _e[1];
    var _f = React.useState(), allStates = _f[0], setAllStates = _f[1];
    var _g = React.useState({}), editCompanies = _g[0], setEditCompanies = _g[1];
    var _h = React.useState({}), editResponse = _h[0], setEditResponse = _h[1];
    var _j = React.useState(false), selectCompanies = _j[0], setSelectCompanies = _j[1];
    var _k = React.useState(), selectedCompanies = _k[0], setSelectedCompanies = _k[1];
    var _l = React.useState([]), cities = _l[0], setCities = _l[1];
    var _m = React.useState([]), labs = _m[0], setLabs = _m[1];
    var initialValues = {
        company: ''
    };
    var companiesList = (0, react_redux_1.useSelector)(function (store) { return store.appConfigList.company_names; });
    var navigateToUserList = function () {
        return nav("/".concat(route_path_1.default.PATHS.User_Logistics));
    };
    var fetchEditDetails = function () {
        (0, user_logistics_api_1.editDetails)(id)
            .then(function (res) {
            var response = res.data;
            var companies = [];
            var selectedCompanies = [];
            if (response.length < 1) {
                var responseData_1 = [];
                companiesList.forEach(function (companyData) {
                    var responseObj = {
                        company_id: companyData.value,
                        company_name: companyData.label,
                        states: [],
                        all_state_access: true
                    };
                    responseData_1.push(responseObj);
                });
                response['access_details'] = responseData_1;
            }
            response.access_details.forEach(function (regionDetails) {
                var obj = {
                    id: regionDetails.company_id,
                    label: regionDetails.company_name,
                    value: regionDetails.company_id
                };
                companies.push(obj);
                companiesList.filter(function (companyDetails) {
                    if (companyDetails.label === regionDetails.company_name) {
                        selectedCompanies.push(companyDetails);
                    }
                });
            });
            setEditResponse(response);
            setEditCompanies(selectedCompanies);
            setSelectCompanies(true);
            setSelectedCompanies(selectedCompanies);
        })
            .catch(function (err) {
        });
    };
    var getStateCityByLab = function (cityName, cityData) {
        for (var state_1 in cityData) {
            var city = cityData[state_1].find(function (city) { return city.name === cityName; });
            if (city) {
                return {
                    state: state_1,
                    cityDetails: city
                };
            }
        }
        return null;
    };
    var getStateByCity = function (cityId, states, cities) {
        var stateData = {};
        var _loop_1 = function (state_2) {
            var cityData = cities[state_2].find(function (city) { return city.id === cityId; });
            if (cityData === null || cityData === void 0 ? void 0 : cityData.name) {
                states['All States'].filter(function (stateDetails) {
                    if (stateDetails.name === state_2) {
                        stateData = stateDetails;
                    }
                });
            }
        };
        for (var state_2 in cities) {
            _loop_1(state_2);
        }
        return stateData;
    };
    var getCityNameById = function (cityId, citiesData) {
        var cityData;
        for (var city in citiesData) {
            var cityDetails = citiesData[city].find(function (city) { return city.id === cityId; });
            if (cityDetails) {
                cityData = cityDetails.name;
            }
        }
        return cityData;
    };
    var getCityName = function (cityId, citiesData) {
        for (var _i = 0, citiesData_1 = citiesData; _i < citiesData_1.length; _i++) {
            var state_3 = citiesData_1[_i];
            var city = state_3.regions.find(function (region) { return region.id === cityId; });
            if (city) {
                return city;
            }
        }
        return null;
    };
    var getFilteredPayload = function (labs, cities, states) {
        var updatedLabs = {};
        Object.keys(labs).map(function (key) {
            if (labs[key].length > 0) {
                updatedLabs[key] = labs[key];
            }
        });
        var payloadCities = [];
        Object.keys(updatedLabs).forEach(function (key) {
            var citiesObj = {};
            var cityData = getStateCityByLab(key, cities);
            if (cityData != null && cityData != undefined) {
                var selectedCityLabs = updatedLabs[cityData.cityDetails.name].map(function (labDetails) {
                    return labDetails.id;
                });
                citiesObj['city_id'] = cityData.cityDetails.id;
                citiesObj['labs'] = selectedCityLabs;
                payloadCities.push(citiesObj);
            }
        });
        var statesPayload = [];
        payloadCities.map(function (citiesData) {
            var stateDetails = getStateByCity(citiesData.city_id, states, cities);
            var obj = {};
            obj['state_id'] = stateDetails.id,
                obj['country_id'] = 1,
                obj['cities'] = [citiesData];
            statesPayload.push(obj);
        });
        statesPayload.forEach(function (stateData) {
            var stateName = states['All States'].filter(function (stateDetails) { return stateDetails.id === stateData.state_id; });
            var selectedCity = allCities.filter(function (cityDetails) { return cityDetails.name === stateName[0].name; });
            stateData.cities.forEach(function (citiesData) {
                var cityName = getCityNameById(citiesData.city_id, cities);
                var selectedLab = allLabs.filter(function (labDetails) { return labDetails.name === cityName; });
                if (selectedLab[0].regions.length === citiesData.labs.length) {
                    citiesData['all_lab_access'] = true;
                    citiesData['labs'] = [];
                }
                else {
                    citiesData['all_lab_access'] = false;
                }
            });
            var hasLabAccessFalse = stateData.cities.some(function (citiesData) { return citiesData.all_lab_access === false; });
            if (selectedCity[0].regions.length === stateData.cities.length && !hasLabAccessFalse) {
                stateData['all_city_access'] = true;
                stateData['cities'] = [];
            }
            else {
                stateData['all_city_access'] = false;
            }
        });
        statesPayload.forEach(function (item, index) {
            var existingStateIndex = statesPayload.findIndex(function (state) { return state.state_id === item.state_id; });
            if (existingStateIndex !== -1 && existingStateIndex !== index) {
                statesPayload[existingStateIndex].cities = __spreadArray(__spreadArray([], statesPayload[existingStateIndex].cities, true), item.cities, true);
                statesPayload.splice(index, 1);
            }
        });
        return statesPayload;
    };
    var checkIsLabExist = function (existingData, labName) {
        for (var _i = 0, existingData_1 = existingData; _i < existingData_1.length; _i++) {
            var state_4 = existingData_1[_i];
            for (var _a = 0, _b = state_4.cities; _a < _b.length; _a++) {
                var city = _b[_a];
                if (city.labs.includes(labName)) {
                    return true;
                }
            }
        }
        return false;
    };
    var removeLab = function (labName, data) {
        for (var _i = 0, data_1 = data; _i < data_1.length; _i++) {
            var state_5 = data_1[_i];
            for (var _a = 0, _b = state_5.cities; _a < _b.length; _a++) {
                var city = _b[_a];
                var labIndex = city.labs.indexOf(labName);
                if (labIndex !== -1) {
                    city.labs.splice(labIndex, 1);
                    return data;
                }
            }
        }
    };
    var compareSubmitResponse = function (payload, values) {
        var _a;
        var existingResponseData = editResponse.access_details.filter(function (companyDetails) { return companyDetails.company_name === values.company.label; });
        var removedStatesData = [];
        if ((_a = existingResponseData[0]) === null || _a === void 0 ? void 0 : _a.states) {
            existingResponseData[0].states.forEach(function (stateDetails) {
                var statesObj = {};
                var citiesData = [];
                if (stateDetails.cities.length > 0) {
                    stateDetails.cities.forEach(function (cityDetails) {
                        var citiesObj = {};
                        var removedLabData = [];
                        if (cityDetails.labs.length > 0) {
                            cityDetails.labs.forEach(function (LabDetails) {
                                var isExist = checkIsLabExist(payload.access_details.added, LabDetails);
                                if (!isExist) {
                                    removedLabData.push(LabDetails);
                                }
                            });
                            console.log(removedLabData, 'removedLabData');
                        }
                        else {
                            var cityName_1 = getCityNameById(cityDetails.city_id, selectedCities);
                            var selectedCityLabs_1 = selectedLabs[cityName_1];
                            var selectedCityAllLabs = allLabs.find(function (labDetails) { return labDetails.name === cityName_1; });
                            if (selectedCityAllLabs.regions.length !== (selectedCityLabs_1 === null || selectedCityLabs_1 === void 0 ? void 0 : selectedCityLabs_1.length)) {
                                selectedCityAllLabs.regions.forEach(function (item1) {
                                    if (!selectedCityLabs_1.some(function (item2) { return item2.name === item1.name; })) {
                                        removedLabData.push(item1.id);
                                    }
                                });
                            }
                        }
                        if (removedLabData.length > 0) {
                            citiesObj['city_id'] = cityDetails.city_id;
                            citiesObj['labs'] = removedLabData;
                            var cityInfo_1 = getCityName(cityDetails.city_id, allCities);
                            var selectedAllLabData = allLabs.find(function (labData) { return labData.name === cityInfo_1.name; });
                            if (removedLabData.length === selectedAllLabData.regions.length) {
                                citiesObj['all_lab_access'] = true;
                                citiesObj['labs'] = [];
                            }
                            else {
                                citiesObj['all_lab_access'] = false;
                            }
                            citiesData.push(citiesObj);
                        }
                    });
                }
                else {
                    var stateName = allStates[0].regions.find(function (stateData) { return stateData.id === stateDetails.state_id; });
                    var selectedStateCities = selectedCities[stateName.name];
                    selectedStateCities.forEach(function (cityDetails) {
                        var citiesObj = {};
                        var removedLabData = [];
                        var selectedCityLabs = selectedLabs[cityDetails.name];
                        var selectedCityAllLabs = allLabs.find(function (labDetails) { return labDetails.name === cityDetails.name; });
                        if (selectedCityAllLabs.regions.length !== (selectedCityLabs === null || selectedCityLabs === void 0 ? void 0 : selectedCityLabs.length)) {
                            selectedCityAllLabs.regions.forEach(function (item1) {
                                if (!selectedCityLabs.some(function (item2) { return item2.name === item1.name; })) {
                                    removedLabData.push(item1.id);
                                }
                            });
                            citiesObj['city_id'] = cityDetails.id;
                            var selectedAllLabData = allLabs.find(function (labData) { return labData.name === cityDetails.name; });
                            if (removedLabData.length === selectedAllLabData.regions.length) {
                                citiesObj['all_lab_access'] = true;
                                citiesObj['labs'] = [];
                            }
                            else {
                                citiesObj['all_lab_access'] = false;
                                citiesObj['labs'] = removedLabData;
                            }
                            citiesData.push(citiesObj);
                        }
                    });
                }
                if (citiesData.length > 0) {
                    statesObj['state_id'] = stateDetails.state_id;
                    statesObj['country_id'] = stateDetails.country_id;
                    var stateName_1 = allStates[0].regions.find(function (stateInfo) { return stateInfo.id === stateDetails.state_id; });
                    var selectedAllCityData = allCities.find(function (cityInfo) { return cityInfo.name === stateName_1.name; });
                    var hasLabAccessFalse = citiesData.some(function (citiesData) { return citiesData.all_lab_access === false; });
                    if (citiesData.length === selectedAllCityData.regions.length && !hasLabAccessFalse) {
                        statesObj['all_city_access'] = true;
                        statesObj['cities'] = [];
                    }
                    else {
                        statesObj['all_city_access'] = false;
                        statesObj['cities'] = citiesData;
                    }
                    removedStatesData.push(statesObj);
                }
            });
        }
        var addedData = [];
        if (removedStatesData.length > 0) {
            removedStatesData.forEach(function (stateDetails) {
                if (!stateDetails.all_city_access) {
                    stateDetails.cities.forEach(function (cityDetails) {
                        if (!cityDetails.all_lab_access) {
                            cityDetails.labs.forEach(function (labDetails) {
                                addedData = removeLab(labDetails, payload.access_details.added);
                            });
                        }
                    });
                }
            });
        }
        return {
            removedData: removedStatesData,
            addedData: addedData.length < 1 ? payload.access_details.added : addedData
        };
    };
    var formSubmitHandler = function (values) {
        var statesPayload = getFilteredPayload(selectedLabs, selectedCities, selectedStates);
        var payload = {
            user_id: id ? id : state.userId,
            company: values.company.label,
            all_company_access: id ? editResponse.all_company_access : state.all_company_access
        };
        if (statesPayload.length === allStates[0].regions.length) {
            payload['all_state_access'] = true,
                payload['access_details'] = {
                    added: []
                };
        }
        else {
            payload['all_state_access'] = false,
                payload['access_details'] = {
                    added: statesPayload,
                    removed: []
                };
        }
        if (id) {
            var data = compareSubmitResponse(payload, values);
            payload['access_details']['added'] = data.addedData;
            payload['access_details']['removed'] = data.removedData;
        }
        (0, user_logistics_api_1.createUpdateRegion)(payload)
            .then(function (response) {
            react_hot_toast_1.default.success("Region successfully created for the ".concat(values.company.label, " company"), { duration: 3000 });
        })
            .catch(function (error) {
            react_hot_toast_1.default.error(error);
        });
    };
    var convertCitiesData = function (data) {
        var obj = {};
        data.forEach(function (citiesDetails) {
            obj[citiesDetails.name] = citiesDetails.regions;
        });
        return obj;
    };
    var setEditData = function (data) {
        if (data) {
            if (data.all_state_access) {
                var obj = {
                    'All States': allStates[0].regions
                };
                setSelectedStates(obj);
                var convertedCities = convertCitiesData(allCities);
                setSelectedCities(convertedCities);
                var convertedLabs = convertCitiesData(allLabs);
                setSelectedLabs(convertedLabs);
            }
            else {
                var responseStates_1 = [];
                data.states.forEach(function (stateDetails) {
                    var stateData = allStates[0].regions.find(function (stateInfo) { return stateDetails.state_id === stateInfo.id; });
                    responseStates_1.push(stateData);
                    if (stateDetails.all_city_access) {
                        var citiesData_2 = allCities.find(function (cityInfo) { return cityInfo.name === stateData.name; });
                        setSelectedCities(function (prevCities) {
                            var _a;
                            return (__assign(__assign({}, prevCities), (_a = {}, _a[stateData.name] = citiesData_2.regions, _a)));
                        });
                        var obj_1 = {};
                        citiesData_2.regions.forEach(function (cityInfo) {
                            allLabs.forEach(function (labDetails) {
                                if (labDetails.name === cityInfo.name) {
                                    obj_1[cityInfo.name] = labDetails.regions;
                                }
                            });
                        });
                        setSelectedLabs(function (prevCities) { return (__assign(__assign({}, prevCities), obj_1)); });
                    }
                    else {
                        var responseCities_1 = [];
                        stateDetails.cities.forEach(function (cityDetails) {
                            var cityData = allCities.find(function (cityInfo) { return cityInfo.name === stateData.name; });
                            var responseCityDetails = cityData.regions.find(function (cityInfo) { return cityInfo.id === cityDetails.city_id; });
                            responseCities_1.push(responseCityDetails);
                            if (cityDetails.all_lab_access) {
                                var labData_1 = allLabs.find(function (labinfo) { return labinfo.name === responseCityDetails.name; });
                                setSelectedLabs(function (prevLabs) {
                                    var _a;
                                    return (__assign(__assign({}, prevLabs), (_a = {}, _a[responseCityDetails.name] = labData_1 === null || labData_1 === void 0 ? void 0 : labData_1.regions, _a)));
                                });
                            }
                            else {
                                var responseLabs_1 = [];
                                cityDetails.labs.forEach(function (labDetails) {
                                    var labData = allLabs.find(function (labinfo) { return labinfo.name === responseCityDetails.name; });
                                    var responseLabDetails = labData.regions.find(function (labinfo) { return labinfo.id === labDetails; });
                                    responseLabs_1.push(responseLabDetails);
                                });
                                setSelectedLabs(function (prevCities) {
                                    var _a;
                                    return (__assign(__assign({}, prevCities), (_a = {}, _a[responseCityDetails.name] = responseLabs_1, _a)));
                                });
                            }
                        });
                        setSelectedCities(function (prevCities) {
                            var _a;
                            return (__assign(__assign({}, prevCities), (_a = {}, _a[stateData.name] = responseCities_1, _a)));
                        });
                    }
                });
                setSelectedStates({
                    'All States': responseStates_1
                });
            }
        }
    };
    var companyOnchangehandler = function (val, setFieldValue, setFieldTouched) {
        setSelectedCities({});
        setSelectedStates({});
        setSelectedLabs({});
        setLabs([]);
        setCities([]);
        setFieldValue("company", val);
        setFieldTouched('company', true);
        if (id) {
            var accessData = editResponse.access_details.find(function (accessDetails) { return accessDetails.company_name.toLowerCase() === val.label.toLowerCase(); });
            setEditData(accessData);
        }
    };
    var multiSelecthandler = function (value) {
        setSelectedCompanies(value);
    };
    var submitCompanySelection = function () {
        var values = [];
        if (editCompanies.length != selectedCompanies.length) {
            selectedCompanies.forEach(function (companyDetails) {
                values.push(companyDetails.label);
            });
            var payload = {
                user_id: id,
                all_company_access: selectedCompanies.length === companiesList.length,
                companies: values
            };
            (0, user_logistics_api_1.submitCompanies)(payload);
        }
        setSelectCompanies(false);
    };
    React.useEffect(function () {
        if (id) {
            fetchEditDetails();
        }
    }, [id]);
    var renderForm = function () {
        return (React.createElement(formik_1.Formik, { initialValues: initialValues, onSubmit: function () { }, enableReinitialize: true }, function (_a) {
            var values = _a.values, errors = _a.errors, setFieldValue = _a.setFieldValue, touched = _a.touched, setFieldTouched = _a.setFieldTouched;
            return (React.createElement(formik_1.Form, { className: "input-fileds-wrapper" },
                React.createElement("div", { className: "tool-bar-wrapper border-bottom no-filter-toolbar" },
                    React.createElement("div", { className: "page-heaidng heading-return-wrapper" },
                        React.createElement(iconButton_component_1.default, { className: "btn-retun", icon: 'icon-left-arrow', onClick: navigateToUserList }),
                        React.createElement("h2", null, !id
                            ? 'Assign Regions'
                            : 'Manage Regions')),
                    React.createElement("div", { className: "page-control-wrapper" },
                        React.createElement(button_component_1.default, { type: "button", buttonName: "Cancel", color: "yellow-outline", onClickHandler: navigateToUserList }),
                        React.createElement(button_component_1.default, { buttonName: "Save", color: "yellow", onClickHandler: function () {
                                if (values.company === '') {
                                    react_hot_toast_1.default.error('Please select a company', { duration: 3000 });
                                }
                                else if (Object.keys(selectedStates).length === 0) {
                                    react_hot_toast_1.default.error('Please select atleast one state', { duration: 3000 });
                                }
                                else if (Object.keys(selectedCities).length === 0) {
                                    react_hot_toast_1.default.error('Please select atleast one City', { duration: 3000 });
                                }
                                else if (Object.keys(selectedLabs).length === 0) {
                                    react_hot_toast_1.default.error('Please select atleast one lab', { duration: 3000 });
                                }
                                if (values.company != '' || Object.keys(selectedCities).length > 0 || Object.keys(selectedStates).length > 0 || Object.keys(selectedLabs).length > 0) {
                                    formSubmitHandler(values);
                                }
                            } }))),
                React.createElement("div", { className: "scrollable-content" },
                    React.createElement("div", { className: "add-slotform" },
                        React.createElement("div", { className: "input-fileds-wrapper" },
                            React.createElement(Grid_1.default, { container: true, columns: { xs: 12, lg: 12 }, columnSpacing: 3 },
                                React.createElement(Grid_1.default, { item: true, xs: 3, lg: 3.5 },
                                    React.createElement(drop_down_component_1.default, { name: "company", value: values === null || values === void 0 ? void 0 : values.company, dropDownList: id ? selectedCompanies : state ? state.selectedCompanies : [], placeholder: 'Company', label: 'Company', required: true, onChange: function (val) { return companyOnchangehandler(val, setFieldValue, setFieldTouched); } }),
                                    errors['company'] && touched['company'] ? (React.createElement("div", { className: "error" }, errors['company'])) : null)))),
                    React.createElement(Region_Selector_1.default, { setSelectedStates: setSelectedStates, setSelectedCities: setSelectedCities, setSelectedLabs: setSelectedLabs, selectedStates: selectedStates, selectedCities: selectedCities, selectedLabs: selectedLabs, values: values, setAllCities: setAllCities, setAllLabs: setAllLabs, setAllStates: setAllStates, setLabs: setLabs, setCities: setCities, cities: cities, labs: labs }))));
        }));
    };
    return React.createElement("div", { className: "create-role-wrapper" },
        renderForm(),
        React.createElement(pop_up_1.default, { open: selectCompanies, popUpSize: "popup-lg" },
            React.createElement("div", { className: "modal-header header-wrapper" },
                React.createElement("p", { className: "sub-heading" }, "Please select companies")),
            React.createElement(Grid_1.default, { item: true, xs: 5 },
                React.createElement(multi_selector_1.default, { label: "Select Company", size: "dropdown-lg", dropDownList: (companiesList === null || companiesList === void 0 ? void 0 : companiesList.length) > 0 ? companiesList : [], onChangeHnadlre: function (event) { return multiSelecthandler(event); }, value: selectedCompanies })),
            React.createElement("div", { className: "modal-footer" },
                React.createElement(button_component_1.default, { onClickHandler: function () {
                        setSelectCompanies(false);
                        navigateToUserList();
                    }, buttonName: "Cancel", color: "yellow-outline" }),
                React.createElement(button_component_1.default, { buttonName: "Assign", color: "yellow", onClickHandler: submitCompanySelection }))));
};
exports.default = AssignRegion;
