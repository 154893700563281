"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var material_1 = require("@mui/material");
var React = require("react");
require("./checkbox.scss");
var CheckboxComponent = function (_a) {
    var checked = _a.checked, label = _a.label, handleChange = _a.handleChange, style = _a.style, id = _a.id, disabled = _a.disabled, name = _a.name, value = _a.value, defaultChecked = _a.defaultChecked;
    console.log('checked insdie CheckboxComponent:', checked);
    return (React.createElement("div", { className: name === 'bookmark' ? 'checkbox-wraper bookmark' : 'checkbox-wraper' },
        React.createElement(material_1.FormControlLabel, { label: label, control: React.createElement(material_1.Checkbox, { checked: checked, onChange: handleChange, inputProps: { 'aria-label': 'controlled' }, disabled: disabled, value: value, defaultChecked: defaultChecked }) })));
};
exports.default = CheckboxComponent;
