"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
var material_1 = require("@mui/material");
var formik_1 = require("formik");
var React = require("react");
var moment = require("moment");
var react_hot_toast_1 = require("react-hot-toast");
var api_urls_1 = require("../../../api/api_urls");
var button_component_1 = require("../../../common/buttons/button_component");
var drop_down_component_1 = require("../../../common/drop_down/drop_down_component");
var pop_up_1 = require("../../../common/modal/pop-up");
var text_field_password_1 = require("../../../common/text_filed/text_field_password");
var text_field_with_datepicker_1 = require("../../../common/text_filed/text_field_with_datepicker");
var text_filed_component_1 = require("../../../common/text_filed/text_filed_component");
var schema_1 = require("../../../constants/schema");
var array_helpers_1 = require("../../../utils/array_helpers");
var api_1 = require("../role_management/api");
var user_logistics_api_1 = require("./user_logistics_api");
var route_path_1 = require("../../../constants/route_path");
var react_router_dom_1 = require("react-router-dom");
var multi_selector_1 = require("../../../common/checkbox/multi_selector");
var react_redux_1 = require("react-redux");
var CreateUserLogistics = function (_a) {
    var openPopToAdd = _a.openPopToAdd, setPageload = _a.setPageload, editData = _a.editData, editListUpdater = _a.editListUpdater, callBackToLoadlist = _a.callBackToLoadlist, setPopToAdd = _a.setPopToAdd;
    var nav = (0, react_router_dom_1.useNavigate)();
    var _b = React.useState(), gender = _b[0], setGender = _b[1];
    var addUserLogistic = {
        firstName: '',
        lastName: '',
        Admin_UserName: '',
        Admin_PassWord: '',
        phone_number: '',
        dob: '',
        address: '',
        email: '',
        gender: '',
        role_id: '',
    };
    var companiesList = (0, react_redux_1.useSelector)(function (store) { return store.appConfigList.company_names; });
    var _c = React.useState(false), openPop = _c[0], setOpenPop = _c[1];
    var _d = React.useState(false), selectCompanies = _d[0], setSelectCompanies = _d[1];
    var _e = React.useState(), selectedCompanies = _e[0], setSelectedCompanies = _e[1];
    var _f = React.useState(false), flags = _f[0], setFlags = _f[1];
    var _g = React.useState(null), creAlredyexits = _g[0], setCreAlredyexits = _g[1];
    var _h = React.useState(), roleList = _h[0], setRoleList = _h[1];
    var _j = React.useState(), userId = _j[0], setUserId = _j[1];
    var handleClosePop = function () {
        setOpenPop(false);
        setPopToAdd(false);
        setGender();
    };
    var fetchRoleList = function (endPoint, payload, domain) {
        (0, api_1.getRoleList)(endPoint, payload, domain)
            .then(function (res) {
            setRoleList(res);
        })
            .catch(function (err) {
            setRoleList([]);
        });
    };
    React.useEffect(function () {
        fetchRoleList(api_urls_1.api_urls.ROLE_LIST, { page: 1, size: 200, app_id: parseInt(process.env.ROLES_APP_ID, 10) }, process.env.ONEX_VIEW_SUPER_ADMIN_BASE_URL);
    }, []);
    var navigation = function () {
        var data = {
            selectedCompanies: selectedCompanies,
            userId: '3a4ca1101be24ef4b1c29ca38cdb429b',
            all_company_access: selectedCompanies.length === companiesList.length
        };
        nav("/".concat(route_path_1.default.PATHS.User_Logistics, "/").concat(route_path_1.default.PATHS.Assign_Region), { state: data });
    };
    var submitCompanySelection = function () {
        var values = [];
        selectedCompanies.forEach(function (companyDetails) {
            values.push(companyDetails.label);
        });
        var payload = {
            user_id: userId,
            all_company_access: selectedCompanies.length === companiesList.length,
            companies: values
        };
        (0, user_logistics_api_1.submitCompanies)(payload)
            .then(function (response) {
            console.log(response, 'opijhug');
            navigation();
        })
            .catch(function (error) {
            react_hot_toast_1.default.error(error);
        });
        navigation();
    };
    var formSubmitHandler = function (values) {
        var _a;
        var payload = {};
        payload.username = values.Admin_UserName.toLocaleUpperCase();
        payload.first_name = values.firstName.toLocaleUpperCase();
        payload.last_name = values.lastName.toLocaleUpperCase();
        payload.role_id = (_a = values === null || values === void 0 ? void 0 : values.role_id) === null || _a === void 0 ? void 0 : _a.value;
        payload.phone_number = values.phone_number;
        payload.dob = moment(values.dob).format('YYYY-MM-DD');
        payload.address = values.address;
        payload.gender = gender;
        payload.email = values.email;
        payload.password = values.Admin_PassWord;
        (0, user_logistics_api_1.createUserLogistic)(payload)
            .then(function (response) {
            console.log(response, 'response');
            react_hot_toast_1.default.success(response.message);
            handleClosePop();
            setFlags(!flags);
            setPageload(!flags);
            setCreAlredyexits(null);
            setUserId(response.data.user_id);
            assignHandler();
        })
            .catch(function (error) {
            react_hot_toast_1.default.error(error);
        });
    };
    var mobileNumberValidation = function (e, value, errors, setErrors) {
        console.log(setErrors, "ijhgf");
        var mobilepayload = {
            phone_number: value,
        };
        var emailpayload = {
            email: value,
        };
        (0, user_logistics_api_1.userlogisticsMobileAndEmailExist)(e.target.name === "email" ? emailpayload : mobilepayload).then(function (res) {
            if (res.data.statusCode === 400) {
                if (e.target.name === "email") {
                    if (res.data.message === 'email must be an email') {
                        setErrors(__assign(__assign({}, errors), { email: 'Please enter a valid email' }));
                    }
                    else {
                        setErrors(__assign(__assign({}, errors), { email: 'Email already in use' }));
                    }
                }
                else {
                    setErrors(__assign(__assign({}, errors), { phone_number: 'Mobile number already in use' }));
                }
            }
            else if (res.data.statusCode === 200) {
                if (e.target.name === "email") {
                    setErrors(__assign(__assign({}, errors), { email: '' }));
                }
                else {
                    setErrors(__assign(__assign({}, errors), { phone_number: '' }));
                }
            }
        });
    };
    var multiSelecthandler = function (value) {
        setSelectedCompanies(value);
    };
    var assignHandler = function () {
        setOpenPop(false);
        setSelectCompanies(true);
    };
    React.useEffect(function () {
        setOpenPop(openPopToAdd);
    }, [openPopToAdd]);
    return (React.createElement("div", null,
        React.createElement(pop_up_1.default, { open: openPop, popUpSize: "popup-lg" },
            React.createElement("div", { className: "modal-header header-wrapper" },
                React.createElement("h2", { className: "heading" }, 'Create Admin User')),
            React.createElement("div", { className: "modal-body" },
                React.createElement(formik_1.Formik, { "enableReinitialize:true": true, initialValues: __assign({}, addUserLogistic), validationSchema: schema_1.schemas.Add_User_Logistics_schema, validateOnBlur: false, onSubmit: formSubmitHandler }, function (_a) {
                    var values = _a.values, errors = _a.errors, setFieldValue = _a.setFieldValue, setFieldTouched = _a.setFieldTouched, touched = _a.touched, setErrors = _a.setErrors;
                    return (React.createElement(formik_1.Form, { className: "input-fileds-wrapper", autoComplete: 'chrome-off' },
                        React.createElement(material_1.Grid, { container: true, columns: { xs: 12 }, columnSpacing: 3 },
                            React.createElement(material_1.Grid, { item: true, xs: 6 },
                                React.createElement(text_filed_component_1.default, { required: true, name: "firstName", onKeyPress: function (e) {
                                        if (new RegExp(/^[a-zA-Z ]*$/).test(e.key)) {
                                        }
                                        else {
                                            e.preventDefault();
                                        }
                                    }, onChange: function (event) {
                                        setFieldValue('firstName', event.trim());
                                        setFieldTouched('firstName', true);
                                    }, label: "First Name ", value: values.firstName }),
                                errors['firstName'] && touched['firstName'] ? (React.createElement("div", { className: "error" }, errors['firstName'])) : null),
                            React.createElement(material_1.Grid, { item: true, xs: 6 },
                                React.createElement(text_filed_component_1.default, { required: true, name: "lastName", onKeyPress: function (e) {
                                        if (new RegExp(/^[a-zA-Z ]*$/).test(e.key)) {
                                        }
                                        else {
                                            e.preventDefault();
                                        }
                                    }, onChange: function (event) {
                                        setFieldValue('lastName', event.trim());
                                        setFieldTouched('lastName', true);
                                    }, label: "Last Name", value: values.lastName }),
                                errors['lastName'] && touched['lastName'] ? (React.createElement("div", { className: "error" }, errors['lastName'])) : null),
                            React.createElement(material_1.Grid, { item: true, xs: 6 },
                                React.createElement(text_filed_component_1.default, { autoComplete: "Admin_UserName", id: "Admin_UserName", required: true, name: "Admin_UserName", onChange: function (event) {
                                        setFieldValue('Admin_UserName', event.trim());
                                        setFieldTouched('Admin_UserName', true);
                                    }, label: "UserName", value: values.Admin_UserName }),
                                errors['Admin_UserName'] && touched['Admin_UserName'] ? (React.createElement("div", { className: "error" }, errors['Admin_UserName'])) : null),
                            React.createElement(material_1.Grid, { item: true, xs: 6 },
                                React.createElement(text_field_password_1.default, { autoComplete: "Admin_PassWord", name: "Admin_PassWord", onChange: function (event) {
                                        setFieldValue('Admin_PassWord', event);
                                        setFieldTouched('Admin_PassWord', true);
                                    }, label: "PassWord", value: values.Admin_PassWord }),
                                errors['Admin_PassWord'] && touched['Admin_PassWord'] ? (React.createElement("div", { className: "error" }, errors['Admin_PassWord'])) : null),
                            React.createElement(material_1.Grid, { item: true, xs: 6 },
                                React.createElement(text_field_with_datepicker_1.default, { name: "dob", type: "dateselection", placeholder: 'DOB', label: "DOB", onChange: function (e) {
                                        setFieldValue('dob', e);
                                        setFieldTouched('dob', true);
                                    }, value: values === null || values === void 0 ? void 0 : values.dob, isFutureDisable: true }),
                                errors['dob'] && touched['dob'] ? (React.createElement("div", { className: "error" }, errors['dob'])) : null),
                            React.createElement(material_1.Grid, { item: true, xs: 3 },
                                React.createElement("div", { className: 'green-tick ' },
                                    React.createElement(material_1.FormLabel, { className: 'radio-btn-label' },
                                        "Gender",
                                        ' ',
                                        React.createElement("span", { className: 'asterisk' })),
                                    React.createElement(material_1.RadioGroup, { row: true, name: 'gender', onChange: function (event) {
                                            setGender(event.target.value);
                                            setFieldValue('gender', event.target.value);
                                            setFieldTouched('gender', true);
                                        }, key: Math.random().toString(), defaultValue: gender },
                                        React.createElement("div", { className: 'row-type-radios ' },
                                            React.createElement(material_1.FormControlLabel, { value: 'male', control: React.createElement(material_1.Radio, null), label: 'Male' }),
                                            React.createElement(material_1.FormControlLabel, { value: 'female', control: React.createElement(material_1.Radio, null), label: 'Female' })))),
                                errors['gender'] && touched['gender'] ? (React.createElement("div", { className: 'error' }, errors['gender'])) : null),
                            React.createElement(material_1.Grid, { item: true, xs: 6 },
                                React.createElement(text_filed_component_1.default, { required: true, name: "email", onChange: function (val) {
                                        setFieldValue('email', val);
                                        setFieldTouched('email', true);
                                        if (val.includes('.com')) {
                                            mobileNumberValidation(event, val, errors, setErrors);
                                        }
                                    }, label: "Email", value: values.email }),
                                errors['email'] && touched['email'] ? (React.createElement("div", { className: "error" }, errors['email'])) : null),
                            React.createElement(material_1.Grid, { item: true, xs: 6 },
                                React.createElement(text_filed_component_1.default, { required: true, name: "phone_number", onChange: function (val) {
                                        setFieldValue('phone_number', val);
                                        setFieldTouched('phone_number', true);
                                        if (val.length === 10) {
                                            mobileNumberValidation(event, val, errors, setErrors);
                                        }
                                    }, onKeyPress: function (e) {
                                        if (new RegExp(/^[0-9]+$/).test(e.key) &&
                                            e.target.value.length <= 9) {
                                        }
                                        else {
                                            e.preventDefault();
                                        }
                                    }, label: "Mobile Number", value: values.phone_number }),
                                errors['phone_number'] && touched['phone_number'] ? (React.createElement("div", { className: "error" }, errors['phone_number'])) : null),
                            React.createElement(material_1.Grid, { item: true, xs: 6 },
                                React.createElement(drop_down_component_1.default, { required: true, name: "role_id", placeholder: "Role Id", dropDownList: (0, array_helpers_1.convertRoleDataResponse)(roleList), onChange: function (val) {
                                        setFieldValue('role_id', val);
                                        setFieldTouched('role_id', true);
                                    }, value: values.role_id }),
                                errors['role_id'] &&
                                    touched['role_id'] &&
                                    values['role_id'] === '' ? (React.createElement("div", { className: "error" }, errors['role_id'])) : null,
                                creAlredyexits ? React.createElement("div", { className: "error" }, creAlredyexits) : null),
                            React.createElement(material_1.Grid, { item: true, xs: 6 },
                                React.createElement(text_filed_component_1.default, { name: "address", placeholder: 'Address', label: "Address", required: false, value: values === null || values === void 0 ? void 0 : values.address, onChange: function (e) {
                                        setFieldValue('address', e);
                                        setFieldTouched('address', true);
                                    } }),
                                errors['address'] && touched['address'] ? (React.createElement("div", { className: "error" }, errors['address'])) : null)),
                        React.createElement("div", { className: "modal-footer" },
                            React.createElement(button_component_1.default, { onClickHandler: handleClosePop, buttonName: "Cancel", color: "yellow-outline" }),
                            React.createElement(button_component_1.default, { type: "submit", buttonName: "Submit", color: "yellow" }))));
                }))),
        React.createElement(pop_up_1.default, { open: selectCompanies, popUpSize: "popup-lg" },
            React.createElement("div", { className: "modal-header header-wrapper" },
                React.createElement("p", { className: "sub-heading" }, "Please select companies")),
            React.createElement(material_1.Grid, { item: true, xs: 5 },
                React.createElement(multi_selector_1.default, { label: "Select Company", size: "dropdown-lg", dropDownList: (companiesList === null || companiesList === void 0 ? void 0 : companiesList.length) > 0 ? companiesList : [], onChangeHnadlre: function (event) { return multiSelecthandler(event); }, value: selectedCompanies })),
            React.createElement("div", { className: "modal-footer" },
                React.createElement(button_component_1.default, { onClickHandler: function () { return setSelectCompanies(false); }, buttonName: "Cancel", color: "yellow-outline" }),
                React.createElement(button_component_1.default, { buttonName: "Assign", color: "yellow", onClickHandler: submitCompanySelection })))));
};
exports.default = CreateUserLogistics;
