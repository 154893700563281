"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
var material_1 = require("@mui/material");
var React = require("react");
require("./Assign_Region.scss");
var checkbox_component_1 = require("../../../common/checkbox/checkbox_component");
var react_redux_1 = require("react-redux");
var array_helpers_1 = require("../../../utils/array_helpers");
var user_logistics_api_1 = require("./user_logistics_api");
var RegionSelector = function (_a) {
    var setSelectedStates = _a.setSelectedStates, setSelectedCities = _a.setSelectedCities, setSelectedLabs = _a.setSelectedLabs, selectedStates = _a.selectedStates, selectedCities = _a.selectedCities, selectedLabs = _a.selectedLabs, values = _a.values, setAllCities = _a.setAllCities, setAllStates = _a.setAllStates, setAllLabs = _a.setAllLabs, setCities = _a.setCities, setLabs = _a.setLabs, cities = _a.cities, labs = _a.labs;
    var stateList = (0, react_redux_1.useSelector)(function (store) { return store.appConfigList; }).stateList;
    var labList = (0, react_redux_1.useSelector)(function (state) { return state.appConfigList.labList; });
    var _b = React.useState([]), statesData = _b[0], setStatesData = _b[1];
    var _c = React.useState(), allCitiesData = _c[0], setAllCitiesData = _c[1];
    var _d = React.useState(), allLabsData = _d[0], setAllLabsData = _d[1];
    var selectedFeatureHandler = function (event, moduleName, featureId, section, featureName, allModuleData, selectedData, items, selectedObj) {
        var _a, _b, _c, _d, _e;
        if (event.target.checked) {
            if (Object.keys(selectedData).length > 0) {
                if (selectedData[moduleName] != undefined) {
                    var isExist = selectedData[moduleName].some(function (module) { return module.name === featureName; });
                    if (!isExist) {
                        var selectedArray = selectedData[moduleName];
                        selectedArray.push(selectedObj);
                        var obj = __assign(__assign({}, selectedData), (_a = {}, _a[moduleName] = selectedArray, _a));
                        if (section === 'States') {
                            setSelectedStates(obj);
                        }
                        else if (section === 'Cities') {
                            setSelectedCities(obj);
                        }
                        else if (section === 'Labs') {
                            setSelectedLabs(obj);
                        }
                    }
                    else {
                        var selectedArray = selectedData[moduleName].filter(function (moduleDetails) { return moduleDetails.name != featureName; });
                        var obj = __assign(__assign({}, selectedData), (_b = {}, _b[moduleName] = selectedArray, _b));
                        if (section === 'States') {
                            var updatedCities = Object.fromEntries(Object.entries(selectedCities).filter(function (_a) {
                                var key = _a[0];
                                return key !== featureName;
                            }));
                            var updatedLabs_1;
                            var unselectedCitiesData = selectedCities[featureName];
                            unselectedCitiesData.filter(function (cityInfo) {
                                updatedLabs_1 = Object.fromEntries(Object.entries(selectedLabs).filter(function (_a) {
                                    var key = _a[0];
                                    return key !== cityInfo.name;
                                }));
                            });
                            setSelectedCities(updatedCities);
                            setSelectedStates(obj);
                            setSelectedLabs(updatedLabs_1);
                        }
                        else if (section === 'Cities') {
                            var updatedLabs = Object.fromEntries(Object.entries(selectedLabs).filter(function (_a) {
                                var key = _a[0];
                                return key !== featureName;
                            }));
                            setSelectedLabs(updatedLabs);
                            setSelectedCities(obj);
                        }
                        else if (section === 'Labs') {
                            setSelectedLabs(obj);
                        }
                    }
                }
                else {
                    var obj = __assign(__assign({}, selectedData), (_c = {}, _c[moduleName] = [selectedObj], _c));
                    if (section === 'States') {
                        setSelectedStates(obj);
                    }
                    else if (section === 'Cities') {
                        setSelectedCities(obj);
                    }
                    else if (section === 'Labs') {
                        setSelectedLabs(obj);
                    }
                }
            }
            else {
                if (section === 'States') {
                    var obj = {};
                    obj[moduleName] = [selectedObj];
                    setSelectedStates(obj);
                }
                else if (section === 'Cities') {
                    var obj = {};
                    obj[moduleName] = [selectedObj];
                    setSelectedCities(obj);
                }
                else if (section === 'Labs') {
                    var obj = {};
                    obj[moduleName] = [selectedObj];
                    setSelectedLabs(obj);
                }
            }
        }
        else {
            var isExist = selectedData[moduleName].some(function (module) { return module.name === featureName; });
            if (!isExist) {
                var selectedArray = selectedData[moduleName];
                selectedArray.push(selectedObj);
                var obj = __assign(__assign({}, selectedData), (_d = {}, _d[moduleName] = selectedArray, _d));
                if (section === 'States') {
                    setSelectedStates(obj);
                }
                else if (section === 'Cities') {
                    setSelectedCities(obj);
                }
                else if (section === 'Labs') {
                    setSelectedLabs(obj);
                }
            }
            else {
                var selectedArray = selectedData[moduleName].filter(function (moduleDetails) { return moduleDetails.name != featureName; });
                var obj = __assign(__assign({}, selectedData), (_e = {}, _e[moduleName] = selectedArray, _e));
                if (section === 'States') {
                    var updatedCities = Object.fromEntries(Object.entries(selectedCities).filter(function (_a) {
                        var key = _a[0];
                        return key !== featureName;
                    }));
                    setSelectedCities(updatedCities);
                    setSelectedStates(obj);
                }
                else if (section === 'Cities') {
                    var updatedLabs = Object.fromEntries(Object.entries(selectedLabs).filter(function (_a) {
                        var key = _a[0];
                        return key !== featureName;
                    }));
                    setSelectedLabs(updatedLabs);
                    setSelectedCities(obj);
                }
                else if (section === 'Labs') {
                    setSelectedLabs(obj);
                }
            }
        }
    };
    var selectAllHandler = function (event, section) {
        if (event.target.checked) {
            if (section === 'Cities') {
                var obj_1 = {};
                cities.map(function (cityDetails) {
                    obj_1[cityDetails.name] = cityDetails.regions;
                });
                setSelectedCities(obj_1);
            }
            else if (section === 'Labs') {
                var obj_2 = {};
                labs.map(function (cityDetails) {
                    obj_2[cityDetails.name] = cityDetails.regions;
                });
                setSelectedLabs(obj_2);
            }
        }
        else {
            if (section === 'Cities') {
                setSelectedCities([]);
            }
            else if (section === 'Labs') {
                setSelectedLabs([]);
            }
        }
    };
    var selectAllModuleWiseHandler = function (event, moduleName, moduleRegions, selectedData, section, allModuleData, moduleData) {
        var _a;
        if (event.target.checked) {
            var updatedData = __assign(__assign({}, selectedData), (_a = {}, _a[moduleName] = moduleRegions, _a));
            if (section === 'States') {
                setSelectedStates(updatedData);
            }
            else if (section === 'Cities') {
                setSelectedCities(updatedData);
            }
            else if (section === 'Labs') {
                setSelectedLabs(updatedData);
            }
            if (section === 'States') {
                setCities(allCitiesData);
            }
            else {
                if (section === 'Cities') {
                    var filteredData_1 = allModuleData.filter(function (module) { return module.name === moduleName; });
                    var isExist = moduleData.some(function (module) { return module.name === moduleName; });
                    if (isExist) {
                        moduleData.map(function (moduleData) {
                            var _a;
                            if (moduleData.name === moduleName) {
                                return __assign(__assign({}, moduleData), (_a = {}, _a[moduleName] = moduleRegions, _a));
                            }
                        });
                    }
                    else {
                        if (labs.length === allLabsData.length) {
                            setLabs(filteredData_1);
                        }
                        else {
                            setLabs(function (prev) { return __spreadArray(__spreadArray([], prev, true), filteredData_1, true); });
                        }
                    }
                }
            }
        }
        else {
            var updatedData = Object.fromEntries(Object.entries(selectedData).filter(function (_a) {
                var key = _a[0];
                return key !== moduleName;
            }));
            if (section === 'States') {
                setSelectedStates(updatedData);
            }
            else if (section === 'Cities') {
                setSelectedCities(updatedData);
            }
            else if (section === 'Labs') {
                setSelectedLabs(updatedData);
            }
            if (section === 'States') {
                setCities(allCitiesData);
            }
            else {
                if (section === 'Cities') {
                    var filteredData = moduleData.filter(function (moduleDetails) { return moduleDetails.name !== moduleName; });
                    if (filteredData.length < 1) {
                        setLabs(allLabsData);
                    }
                    else {
                        setLabs(filteredData);
                    }
                }
            }
        }
    };
    React.useEffect(function () {
        if (Object.keys(selectedStates).length > 0) {
            var filteredData_2 = [];
            selectedStates['All States'].forEach(function (stateDetails) {
                allCitiesData.map(function (citydetails) {
                    if (stateDetails.name === citydetails.name) {
                        filteredData_2.push(citydetails);
                    }
                });
            });
            setCities(filteredData_2);
            setLabs([]);
        }
        if (Object.keys(selectedCities).length > 0) {
            var filteredData_3 = [];
            Object.keys(selectedCities).forEach(function (key) {
                selectedCities[key].forEach(function (cityData) {
                    allLabsData.map(function (labDetails) {
                        if (cityData.name === labDetails.name) {
                            filteredData_3.push(labDetails);
                        }
                    });
                });
            });
            setLabs(filteredData_3);
        }
    }, [selectedStates, selectedCities, selectedLabs]);
    React.useEffect(function () {
        setStatesData((0, array_helpers_1.convertStateResponse)(stateList));
        setAllStates((0, array_helpers_1.convertStateResponse)(stateList));
        setAllLabsData((0, array_helpers_1.convertLabListResponse)(labList));
        setAllLabs((0, array_helpers_1.convertLabListResponse)(labList));
        (0, user_logistics_api_1.getCitiesData)()
            .then(function (res) {
            var data = (0, array_helpers_1.convertCityResponse)(res.cities);
            setAllCitiesData(data);
            setAllCities(data);
        })
            .catch(function (err) {
            console.log(err);
        });
    }, []);
    var checkIsSelected = function (name, moduleName, selectedData) {
        if (selectedData[moduleName]) {
            return selectedData[moduleName] &&
                selectedData[moduleName].some(function (item) { return (item === null || item === void 0 ? void 0 : item.name) === name; });
        }
        else {
            return false;
        }
    };
    var isAllSelectedModuleWise = function (feature, moduleName, selectedData) {
        if (!selectedData[moduleName])
            return false;
        var selectedRegionsForModule = selectedData[moduleName] || [];
        var allRegionsSelected = feature.regions.every(function (region) {
            return selectedRegionsForModule.some(function (selectedRegion) { return (selectedRegion === null || selectedRegion === void 0 ? void 0 : selectedRegion.name) === (region === null || region === void 0 ? void 0 : region.name); });
        });
        return allRegionsSelected;
    };
    var isAllModulesSelected = function (allModuleData, selectedData) {
        if (!selectedData || Object.keys(selectedData).length === 0) {
            return false;
        }
        var allModuleNames = Array.from(new Set(allModuleData.map(function (module) { return module.name; })));
        return allModuleNames.every(function (moduleName) {
            var moduleInData = allModuleData.find(function (module) { return module.name === moduleName; });
            var selectedModuleRegions = selectedData[moduleName];
            return (selectedModuleRegions &&
                selectedModuleRegions.length === (moduleInData === null || moduleInData === void 0 ? void 0 : moduleInData.regions.length));
        });
    };
    var renderChipsLeft = function (items, iconName, section, selectedData) {
        var _a;
        var allModuleData = section === 'States' ? allCitiesData : section === 'Cities' ? allLabsData : [];
        var moduleData = section === 'States' ? cities : section === 'Cities' ? labs : [];
        return (React.createElement("div", { className: "list-container" },
            section !== 'States' && React.createElement("div", null,
                React.createElement(checkbox_component_1.default, { label: "Select All ".concat(section), handleChange: function (event) { return selectAllHandler(event, section); }, checked: isAllModulesSelected(items, section === 'Cities' ? selectedCities : selectedLabs) })),
            React.createElement(material_1.List, { className: "region-list-wrapper" }, (_a = items === null || items === void 0 ? void 0 : items.sort(function (a, b) { return (a.name > b.name ? 1 : -1); })) === null || _a === void 0 ? void 0 : _a.map(function (feature) {
                var _a;
                return (React.createElement(material_1.ListItem, { key: feature.name, className: "feature-container" },
                    React.createElement("div", { className: "feature-heading-wrapper" },
                        React.createElement(checkbox_component_1.default, { label: feature.name, handleChange: function (event) { return selectAllModuleWiseHandler(event, feature.name, feature.regions, selectedData, section, allModuleData, moduleData); }, checked: isAllSelectedModuleWise(feature, feature.name, selectedData) })),
                    React.createElement("p", { className: "section-desc" }, "Click on the above heading to select all the options or select one by one from below list."),
                    React.createElement("div", { className: "feature-chips-wrapper" }, (_a = feature === null || feature === void 0 ? void 0 : feature.regions) === null || _a === void 0 ? void 0 : _a.map(function (x_feature, index) {
                        return (React.createElement("label", { key: index, className: "chip-label custom-chip boxed-chip  feature-chip ".concat(checkIsSelected(x_feature.name, feature.name, selectedData)
                                ? 'activeChip'
                                : '') },
                            React.createElement("input", { type: "checkbox", key: x_feature.id, name: "chip", id: "feature-chip", className: "boxed-chip  feature-chip", onChange: function () {
                                    selectedFeatureHandler(event, feature.name, x_feature.id, section, x_feature.name, allModuleData, selectedData, moduleData, x_feature);
                                } }),
                            x_feature.name,
                            ' ',
                            React.createElement("span", { className: "".concat(iconName, " ") })));
                    }))));
            }))));
    };
    var renderPage = function () {
        return (React.createElement("div", { className: "transfer-list-wrapper" },
            React.createElement(material_1.Grid, { container: true, columns: { xs: 12, lg: 12 }, columnSpacing: 2 },
                React.createElement(material_1.Grid, { item: true, xs: 3.8 },
                    React.createElement("div", { className: "options-containers features-details-wrapper" },
                        React.createElement("h2", { className: "section-title" }, "States"),
                        React.createElement("p", { className: "section-desc" }, "Please select the states you want to assign.")),
                    React.createElement("div", { className: "list-container-wrapper" }, renderChipsLeft(statesData, 'icon-add', 'States', selectedStates))),
                React.createElement(material_1.Grid, { item: true, xs: 0.1 },
                    React.createElement("div", { style: {
                            borderLeft: '1px solid #e0e0e0',
                            height: '100%',
                            width: '0',
                        } })),
                React.createElement(material_1.Grid, { item: true, xs: 3.9 },
                    React.createElement("div", { className: "options-containers features-details-wrapper" },
                        React.createElement("h2", { className: "section-title" }, "Cities"),
                        React.createElement("p", { className: "section-desc" }, "Please select the cities you want to assign.")),
                    React.createElement("div", { className: "list-container-wrapper" }, cities.length > 0 ? renderChipsLeft(cities, 'icon-add', 'Cities', selectedCities) :
                        React.createElement("div", { className: 'list-no-data-wrapper' },
                            React.createElement("span", { style: { padding: '10px', border: '2px solid #E0E0E0', borderRadius: '4px', fontFamily: 'inherit', backgroundColor: 'whitesmoke' } }, "Please select a state to get the cities")))),
                React.createElement(material_1.Grid, { item: true, xs: 0.1 },
                    React.createElement("div", { style: {
                            borderLeft: '1px solid #e0e0e0',
                            height: '100%',
                            width: '0',
                        } })),
                React.createElement(material_1.Grid, { item: true, xs: 3.8 },
                    React.createElement("div", { className: "options-containers features-details-wrapper" },
                        React.createElement("h2", { className: "section-title" }, "Labs"),
                        React.createElement("p", { className: "section-desc" }, "Please select the labs you want to assign.")),
                    React.createElement("div", { className: "list-container-wrapper" }, labs.length > 0 ? renderChipsLeft(labs, 'icon-add', 'Labs', selectedLabs) :
                        React.createElement("div", { className: 'list-no-data-wrapper' },
                            React.createElement("span", { style: { padding: '10px', border: '2px solid #E0E0E0', borderRadius: '4px', fontFamily: 'inherit', backgroundColor: 'whitesmoke' } }, "Please select a city to get the Labs")))))));
    };
    return React.createElement(React.Fragment, null, values.company != '' && renderPage());
};
exports.default = RegionSelector;
